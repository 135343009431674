<template>
    <div class="wrapper">
      <div class="aside">
        <div class="logo">
          <img src="../assets/logo_color.png" alt="" height="76px">
        </div>
        <v-sidebar></v-sidebar>
      </div>   
      <!-- 主题内容 -->
      <div class="container">
        <!-- <keep-alive> -->
          <router-view></router-view>
        <!-- </keep-alive> -->
      </div>
    </div>
</template>

<script>
  import vSidebar from './SideBar.vue';
  export default {
    data(){
        return {
          user:'',
          hasTask:false,
        }
    },
    methods:{},
    components:{
      vSidebar,
    },
  }
</script>

<style lang="scss">
.wrapper{
  background-color: $light-base;
  // z-index: -99;
  // position: relative;
  display: flex;
  min-height: 100vh;
  // width: 100vw;
  min-width: 1300px;
  .overline{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .aside{
    background-color: $light-base;
    width: 130px;
    .logo{
      text-align: center;
      // border-bottom: 1px solid $text-dark;
      img{
        margin: 28px 0px 20px 0;
      }
      // height: 90px;
    }
  }
  .container{
    width: calc(100vw - 130px);
    background-color: $light-base;
    height: 100vh;
    flex:1;
    padding: 28px;
  }   
}
</style>
