<template>
  <div id="elmenu" class="elmenu">
    <el-menu
      router
      :default-active="onRoutes"
      background-color="#f0f4f8"
      text-color="#323e52"
      active-text-color="#edf0f4"
      unique-opened
      is-opened>
      <template v-for="(item, index) in menus1" >
      	<!-- 只有一级菜单 -->
        <el-menu-item v-if="!item.children" :key="index" :index="item.path" :id="item.path">
          <i :class="item.icon"></i>
          <span class="span4" slot="title">{{item.name}}</span>
          <el-badge :value="noticeNum" :max="99" class="item" :hidden="item.name !=='通知' || noticeNum ==0" v-if="item.name == '通知'"></el-badge>
        </el-menu-item>
        <!-- 有二级菜单 -->
        <el-submenu v-else :key="index" :index="item.icon" :id="item.path">
          <template slot="title">
            <i :class="item.icon"></i>
            <span class="span4" slot="title">{{item.name}}</span>
          </template> 
          <template v-for="(etem, index) in item.children">
            <el-menu-item :index="etem.path" :key="index">
              {{etem.name}}
            </el-menu-item>
          </template>
        </el-submenu>
      </template>
    </el-menu>
    <div class="user">
      <el-dropdown @command="handleuser">
        <span>
          <el-avatar icon="el-icon-user-solid" :src="infomation.avatar" :key="infomation.avatar"></el-avatar>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item disabled>
            <div>{{infomation.user_name}}</div>
            <!-- <div>{{infomation.department}}</div>
            <div>{{infomation.company}}</div> -->
          </el-dropdown-item>
          <el-dropdown-item command="a" divided>个人设置</el-dropdown-item>
          <el-dropdown-item command="b" divided>退出账户</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>


<script>

export default {
  data() {
    return {
      menus:[],
      menus1: [
        {
	        name: '人员管理',
	        icon: 'el-icon-user',
          // path:'/group_management',
          children: [
            {name: '安服支撑', path: '/group_management/sec_services'},
            {name: '运营系统', path: '/group_management/operations'},
            {name: '销售系统', path: '/group_management/sales'},
            {name: '赋能系统', path: '/group_management/plugins_sys'},
	        ]
        },
        {
	        name: '硬件信息',
	        icon: 'el-icon-notebook-2',
	        path: '/hd_info'
        },
        {
	        name: '用户',
	        icon: 'el-icon-receiving',
	        path: '/setting'
        },
      ],
      infomation:{
        avatar:''
      },
      noticeNum:0,
    }
  }, 
  methods:{
    chooseIcon(item, route) {
       var n = 0 // 用于判断当前一级菜单下的二级菜单是否被点击
       for(var i = 0; i < item.children.length; i++) {
         if(route.includes(item.children[i].path)) {
           n = 1
         }
       }
       if(n == 1) { // 被点击了，返回高亮图标
         return item.Aicon
       } else { // 未被点击，返回未高亮图标
         return item.icon
       }
    },
    handleuser(command){
      switch(command){
        case 'a':
          this.$router.push({path:'/setting'}).catch(err => {
            console.log('错误跳转',err)
          });
          break;
        case 'b':
          this.$http({
            method:'post',
            url: '/user/logout',
          }).then(res => {
            if(res.data.code==200){
              localStorage.removeItem('ywInfo');
              this.$router.push('/login'); 
            }
          })
          break;     
      }
    },
  },
  created(){
    if(localStorage.getItem("ywInfo")){
      this.infomation.user_name = JSON.parse(localStorage.getItem("ywInfo")).user_name;
    }
  },
  computed:{
    onRoutes(){
      let activename;
      let arr = this.$route.path.split('/');
      let newArr = arr.filter(item => item);
      if(newArr.length>2){
        activename = `/${newArr[0]}/${newArr[1]}`;
      }else{
        activename = this.$route.path;;
      }
      return activename
      // return this.$route.path;
    }
  },
  mounted() {
    window.addEventListener('setItem', (e) => {
      this.noticeNum = e.newValue
    });
  },
  
}
</script>


<style lang="scss" scoped>
.elmenu ::v-deep {
  height: calc(100vh - 10px - 116px);
  font-size: 16px;
  font-weight: bold;
  position: relative;
  .el-menu{
    border: none; // 解决菜单右边不对齐的问题
    [class^=el-icon-]{
      color:$defult;
      margin-right: 0px;
    }
    // 二级菜单样式
    .el-submenu{
      .el-submenu__title{
        i{
          color: $defult;
        }
        .el-submenu__icon-arrow{
          right: 10px;
        }
      }
      .el-menu-item {
        padding: 0;
        min-width: unset!important;;
        padding-left: 58px !important;
        
      }
    }
    .is-active{
      background-color: $defult!important;
      // border-radius: 0 24px 24px 0;
      i{
        color: #eee;
      }
    }
  }
  .user{
    text-align: center;
    position: absolute;
    bottom:30px;
    left:calc(130px/2 - 20px);
  }
  // .el-menu .el-menu--inline{
  //   transition:all 0.3s;
  // }
}

</style>


